(function ($) {
  $(document).ready(function () {
    //show and hide product tab
    $(function () {
      $("#reseller-product-1").addClass("active");
      $("#reseller-product-1-content").addClass("active");
    }),
      $(".col-reseller-product").click(function () {
        $(".col-reseller-product").removeClass("active");
        $(".row-reseller-product-content").removeClass("active");
        $(this).addClass("active");
        var id = $(this).attr("id");
        var idContent = "#" + id + "-content";
        $(idContent).addClass("active");
      }),
      //product features toggle
      $(".feature-title").click(function () {
        $(this).parent(".single-feature").toggleClass("active");
        $(this).siblings(".feature-description").slideToggle();
        $(this).children(".feature-arrow").toggleClass("active");
      }),
      //show and hide modal with form in block with offer
      $(".open-form").click(function () {
        $(this).siblings(".product-form").addClass("show");
        $(this).parent().parent().parent(".block-offer").addClass("overlay");
      }),
      $(".close-form").click(function () {
        $(this).parent(".product-form").removeClass("show");
        $(this).parent().parent().parent().parent(".block-offer").removeClass("overlay");
      }),
      //show and hide modal with form in block with contact
      $(".open-form-contact").click(function () {
        $(this).siblings(".contact-form").addClass("show");
      }),
      $(".close-form-contact").click(function () {
        $(this).parent(".contact-form").removeClass("show");
      }),
      //scroll to top
      $(".footer-scroll-to-top").click(function () {
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          "slow"
        );
      }),
      //mobile menu
      $(".header-menu-mobile").click(function () {
        $(this).toggleClass("open");
        $(".header-menu-content").toggleClass("open");
      }),
      $(".header-menu .menu-item").click(function () {
        if ($(document).scrollTop() < 50) {
          $("html").addClass("padding");
        } else {
          $("html").removeClass("padding");
        }
        $(".header-menu-mobile").removeClass("open");
        $(".header-menu-content").removeClass("open");
      }),
      //submenu
      $(".menu-item-has-children>a").click(function (e) {
        var windowWidth = $(window).width();
        if (windowWidth < 1200) {
          e.preventDefault();
          $(this).toggleClass("open");
          $(this).siblings(".sub-menu").toggleClass("open");
        }
      }),
      $(".menu-item-has-children").mouseenter(function () {
        var windowWidth = $(window).width();
        if (windowWidth >= 1200) {
          $(this).children("a").addClass("open");
          $(this).children(".sub-menu").addClass("open");
        }
      }),
      $(".menu-item-has-children").mouseleave(function () {
        var windowWidth = $(window).width();
        if (windowWidth >= 1200) {
          $(this).children("a").removeClass("open");
          $(this).children(".sub-menu").removeClass("open");
        }
      }),
      //fixed menu
      $(window).scroll(function () {
        if ($(document).scrollTop() > 50) {
          $(".site-header").addClass("fixed");
        } else {
          $(".site-header").removeClass("fixed");
        }
      });

    window.CookieConsentDeclaration = window.CookieConsentDeclaration || {};
    let isCookieBotDialogOpen = false;

    window.CookiebotCallback_OnAccept = function () {
      $(".wsc-cookie-bar").hide();
    };
    window.CookiebotCallback_OnDialogDisplay = function () {
      if (!isCookieBotDialogOpen) document.querySelector("#CybotCookiebotDialog").style = "display: none;";
    };
    $(".cookie-button.acceptance").click(function () {
      Cookiebot.submitCustomConsent(true, true, true);
      $(".wsc-cookie-bar").hide();
      if (isCookieBotDialogOpen) document.querySelector("#CybotCookiebotDialog").style = "display: none;";
    });

    $(".cookie-button.more-options-cookiebot").click(function () {
      isCookieBotDialogOpen = true;
      Cookiebot.renew();
    });

    $(".cookie-button.more-options").click(function () {
      const cookieBotDialog = document.querySelector("#CybotCookiebotDialog");

      isCookieBotDialogOpen = true;

      if (!cookieBotDialog) {
        Cookiebot.renew();
        return;
      }

      if (!cookieBotDialog?.classList.contains("CybotCookiebotDialogActive")) {
        cookieBotDialog.classList.add("CybotCookiebotDialogActive");
      }

      cookieBotDialog.style = "display: flex;";
    });
  });
})(jQuery);

import Swiper from "swiper";
import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);

//Slider with images
let imagesSlider = new Swiper(".slider-with-images", {
  loop: true,
  slidesPerView: 2,
  centeredSlides: true,
  spaceBetween: 20,
  autoplay: {
    delay: 2000,
  },
});
